const fontSizes = {
  TITLE: 26,
  PAGE_TITLE: 20,
  SECTION_TITLE: 13,
  CARD_TITLE: 14,

  BODY: 12,
  BODY_SMALL: 10,
  BODY_EXTRA_SMALL: 8,
  BUTTON_LABEL: 14,
  INPUT_LABEL: 11,
  MENU: 9,

  DANGER: 12,
  FORM_ITEM: 11,
}

const lineHeights = {
  TITLE: 32,
  PAGE_TITLE: 24,
  SECTION_TITLE: 14,
  CARD_TITLE: 17,

  BODY: 14,
  BODY_SMALL: 12,
  BODY_EXTRA_SMALL: 10,
  BUTTON_LABEL: 16,
  INPUT_LABEL: 13,
  MENU: 9,

  DANGER: 14,
  FORM_ITEM: 13,
}

export { lineHeights, fontSizes }
