import { useQuery } from '@tanstack/react-query'
import { DeliveryNoteLine } from '../../../domain/Line'
import ApiService from '../../api.service'
import { queryClient } from '../../../utilities/queryClient'

export type UseGetFilteredLinesArgs = {
  recipientAccountId: string
  unbilled?: boolean
  userAccountId?: string
  isBillable?: boolean
}

const getFilteredLines = async (filter: UseGetFilteredLinesArgs): Promise<DeliveryNoteLine[]> => {
  if (!filter.recipientAccountId) {
    return []
  }
  const response = await ApiService.get('/line/deliveryNote', filter)
  return response
}

export function useGetFilteredLines(args: UseGetFilteredLinesArgs) {
  function getQueryKey() {
    return [
      'lines',
      args.recipientAccountId || '',
      args.unbilled ? 'unbilled' : '',
      args.userAccountId || '',
      args.isBillable,
    ].filter(Boolean)
  }

  function query() {
    return useQuery<DeliveryNoteLine[], Error>({
      queryKey: getQueryKey(),
      queryFn: () => getFilteredLines(args),
      keepPreviousData: true,
    })
  }

  return {
    getQueryKey,
    query,
  }
}

const CarrierLineService = {
  useGetFilteredLines,
}

export default CarrierLineService
