import { InterfaceColors } from '../src/utilities/styling/theme/theme'

export enum InvoiceTypeEnum {
  CARRIER_FEES = 1,
}

export enum InvoiceStatusEnum {
  DRAFT = 1,
  SEND = 5,
  PAYMENT_IN_PROGRESS = 9,
  PAID = 10,
  UNPAID = 15,
}

export interface InvoiceStatus {
  label: string
  value: InvoiceStatusEnum
  color: keyof InterfaceColors
  backgroundColor: keyof InterfaceColors
}

export const invoiceStatusColors: Record<InvoiceStatusEnum, keyof InterfaceColors> = {
  [InvoiceStatusEnum.DRAFT]: 'text-dark-3',
  [InvoiceStatusEnum.SEND]: 'color-warning',
  [InvoiceStatusEnum.PAYMENT_IN_PROGRESS]: 'color-warning',
  [InvoiceStatusEnum.PAID]: 'text-dark-1',
  [InvoiceStatusEnum.UNPAID]: 'color-danger',
}

export const INVOICE_STATUSES: InvoiceStatus[] = [
  {
    label: 'Brouillon',
    value: InvoiceStatusEnum.DRAFT,
    color: 'text-light-1',
    backgroundColor: 'text-dark-3',
  },
  {
    label: 'Envoyée',
    value: InvoiceStatusEnum.SEND,
    color: 'text-light-1',
    backgroundColor: 'color-warning',
  },
  {
    label: 'Paiement en cours',
    value: InvoiceStatusEnum.PAYMENT_IN_PROGRESS,
    color: 'text-light-1',
    backgroundColor: 'color-warning',
  },
  {
    label: 'Payée',
    value: InvoiceStatusEnum.PAID,
    color: 'text-dark-1',
    backgroundColor: 'color-secondary',
  },
  {
    label: 'Impayée',
    value: InvoiceStatusEnum.UNPAID,
    color: 'text-light-1',
    backgroundColor: 'color-danger',
  },
]
