import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  PageTitle,
  SectionTitle,
  Spacer,
  Typography,
} from '../../../../components'
import AccountPicker from '../../../../components/AccountPicker'
import DeliveryNoteLinePickerCard from '../../../../components/DeliveryNoteLinePickerCard'
import InvoiceNumber from '../../../../components/InvoiceNumber'
import TotalSummaryCard from '../../../../components/TotalSummaryCard'
import { DeliveryNoteLine } from '../../../../domain/Line'
import { CarrierStackParamList } from '../../../../navigation/CarrierAppNavigationStack/CarrierNavigationStack.model'
import { ProducerStackParamList } from '../../../../navigation/ProducerNavigationStack/ProducerNavigationStack.model'
import CarrierLineService from '../../../../services/carrier/line'
import InvoiceService from '../../../../services/invoice'
import useAuthenticationContext from '../../../../utilities/hook/useAuthenticationContext'
import { ContentWrapper, ScreenSafeAreaWrapper } from '../../../../utilities/styling/wrappers'
import {
  ButtonWrapper,
  ConditionsWrapper,
  DueDateWrapper,
  InvoiceNumberWrapper,
} from './UserCreateInvoiceScreen.styles'
import AccountService from '../../../../services/billing/account'

const CreateInvoiceScreen = () => {
  const [selectedAccount, setSelectedAccount] = useState<string>('')
  const { getAccessInfos } = useAuthenticationContext()
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')
  const [selectedLines, setSelectedLines] = useState<DeliveryNoteLine[]>([])
  const navigationProducer = useNavigation<StackNavigationProp<ProducerStackParamList>>()
  const navigationCarrier = useNavigation<StackNavigationProp<CarrierStackParamList>>()

  const [dueDate, setDueDate] = useState<Date | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const accessInfo = getAccessInfos()

  const filterDN = {
    recipientAccountId: selectedAccount,
    unbilled: true,
    isBillable: true,
  }
  const deliveryNoteLines = CarrierLineService.useGetFilteredLines(filterDN).query().data

  useEffect(() => {
    InvoiceService.getDefaultDueDate(accessInfo).then((defaultDueDate) => {
      setDueDate(defaultDueDate)
    })
  }, [accessInfo])

  const filterAccount = {
    isBillable: true,
    unbilled: true,
    useSimplified: true,
    addUnbilledDNCount: true,
  }

  const onChangeTimeStart = (value: any) => {
    setDueDate(value)
  }

  const createInvoice = async () => {
    if (selectedLines.length === 0 || !dueDate) {
      return
    }

    setIsLoading(true)
    setErrorMessage('')

    const payload = {
      recipientId: selectedAccount,
      parentLines: selectedLines.map((line) => line.lineId),
      customIdentifier: invoiceNumber,
      dueDate,
    }
    await InvoiceService.create(payload)
      .then((createdInvoice) => {
        if (getAccessInfos().carrierId) {
          navigationCarrier.navigate('EditInvoice', {
            invoiceId: createdInvoice.id,
          })
        } else {
          navigationProducer.navigate('EditInvoice', {
            invoiceId: createdInvoice.id,
          })
        }
        InvoiceService.useGetInvoices({ accountId: getAccessInfos().accountId }).invalidate()
        AccountService.useGetManyAccounts({
          profileId: '',
          unbilled: false,
          useSimplified: false,
          addUnbilledDNCount: false,
          invoicedByIssuerId: getAccessInfos().accountId,
        }).invalidate()
      })
      .catch((error) => {
        setErrorMessage('Un problème est survenu lors de la création de la facture')
        setDueDate(undefined)
      })

    setIsLoading(false)
  }

  return (
    <ScreenSafeAreaWrapper withBottomNav>
      <ContentWrapper>
        <PageTitle title="Générer une facture" />
        <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
        <AccountPicker
          label={'Choisir un client'}
          onSelected={(account) => {
            setErrorMessage('')
            setSelectedAccount(account)
            setSelectedLines([])
          }}
          filter={filterAccount}
        />

        {selectedAccount && !errorMessage ? (
          <>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <SectionTitle title={'Choisir les BL à facturer'} />
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            {deliveryNoteLines?.map((deliveryNoteLine: DeliveryNoteLine) => (
              <DeliveryNoteLinePickerCard
                label={'Choisir les BL à facturer'}
                onSelectionUpdated={setSelectedLines}
                filter={filterDN}
                deliveryNoteLine={deliveryNoteLine}
                selectedLines={selectedLines}
              />
            ))}
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <TotalSummaryCard items={selectedLines.map((line) => line.deliveryNote.totalAmounts)} />
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <SectionTitle title="Informations et conditions" />
            <Spacer size={1} axis={Spacer.AxisEnum.VERTICAL} />
            <ConditionsWrapper>
              <DueDateWrapper>
                <DatePicker
                  id="dueDate"
                  label="Échéance de règlement"
                  onChange={onChangeTimeStart}
                  value={dueDate}
                  mode={'date'}
                  outputFormat="date"
                  small
                  boldInputLabel
                />
              </DueDateWrapper>
              <InvoiceNumberWrapper>
                <InvoiceNumber onEdit={setInvoiceNumber} />
              </InvoiceNumberWrapper>
            </ConditionsWrapper>

            <Spacer size={4} axis={Spacer.AxisEnum.VERTICAL} />
            <ButtonWrapper>
              <Button
                loading={isLoading}
                disabled={isLoading || selectedLines.length === 0}
                label="Créer la facture"
                onPress={createInvoice}
              />
            </ButtonWrapper>
          </>
        ) : null}
        {errorMessage ? (
          <>
            <Spacer size={2} axis={Spacer.AxisEnum.VERTICAL} />
            <Typography.Body colorName="color-danger">{errorMessage}</Typography.Body>
          </>
        ) : null}
      </ContentWrapper>
    </ScreenSafeAreaWrapper>
  )
}

export default CreateInvoiceScreen
