import React from 'react'
import { DeliveryNoteLinePickerCardProps } from './DeliveryNoteLinePickerCard.model'
import { DeliveryNoteLine } from '../../domain/Line'
import { TouchableOpacity, View } from 'react-native'
import FormatUtils from '../../utilities/utils/format'
import Spacer from '../Spacer'
import Typography from '../Typography'
import Icon, { Icons } from '../Icon'
import { useTheme } from 'styled-components/native'
import {
  LabelWrapperLeft,
  LabelWrapperRight,
  DeliveryNoteLineCardWrapper,
  StyledCheckboxWrapper,
  LeftIconWrapper,
  CardTitleWrapper,
} from './DeliveryNoteLinePickerCard.styles'
import Card from '../Card'
import DropShadow from 'react-native-drop-shadow'

const DeliveryNoteLinePickerCard = ({
  onSelectionUpdated,
  deliveryNoteLine,
  selectedLines,
}: DeliveryNoteLinePickerCardProps) => {
  const theme = useTheme()

  const toggleLineSelection = (line: DeliveryNoteLine) => {
    onSelectionUpdated((prevSelected: DeliveryNoteLine[]) => {
      if (prevSelected.some((l) => l.lineId === line.lineId)) {
        return prevSelected.filter((l) => l.lineId !== line.lineId)
      } else {
        return [...prevSelected, line]
      }
    })
  }

  const isSelected = selectedLines.some((l) => l.lineId === deliveryNoteLine.lineId)
  return (
    <View key={deliveryNoteLine.lineId} style={{ width: '100%' }}>
      <Card noMargin>
        <DropShadow
          style={{
            shadowColor: theme.colors['color-shadow'],
            shadowOffset: {
              width: 0,
              height: 3,
            },
            shadowOpacity: 1,
            shadowRadius: theme.borderRadius * 0.3,
            borderRadius: theme.borderRadius * 1.4,
          }}
        >
          <DeliveryNoteLineCardWrapper>
            <LeftIconWrapper>
              <Icon
                type={Icons.MaterialCommunityIcons}
                name="file-document-outline"
                size={theme.spacingUnit * 3}
                color="color-primary"
              />
            </LeftIconWrapper>
            <Spacer size={0.5} axis={Spacer.AxisEnum.HORIZONTAL} />
            <LabelWrapperLeft>
              <CardTitleWrapper>
                <Typography.CardTitle>{deliveryNoteLine.label}</Typography.CardTitle>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name="download-circle"
                  size={theme.spacingUnit * 2}
                  color="color-primary"
                />
              </CardTitleWrapper>
              <Typography.BodySmall colorName="text-dark-3">
                {deliveryNoteLine.sublabel}
              </Typography.BodySmall>
            </LabelWrapperLeft>
            <Spacer size={3} axis={Spacer.AxisEnum.HORIZONTAL} />
            <LabelWrapperRight>
              <Typography.BodyExtraSmall colorName="text-dark-3">
                Total HT
              </Typography.BodyExtraSmall>
              <Spacer size={0.15} axis={Spacer.AxisEnum.HORIZONTAL} />
              <Typography.BodySmall bold colorName={'text-dark-1'}>
                {FormatUtils.formatPrice(deliveryNoteLine.deliveryNote.totalAmounts.totalHT)}
              </Typography.BodySmall>
            </LabelWrapperRight>
            <Spacer size={0.3} axis={Spacer.AxisEnum.HORIZONTAL} />
            <StyledCheckboxWrapper>
              <TouchableOpacity onPress={() => toggleLineSelection(deliveryNoteLine)}>
                <Icon
                  type={Icons.MaterialCommunityIcons}
                  name={isSelected ? 'checkbox-marked' : 'checkbox-blank-outline'}
                  color={theme.colors[isSelected ? 'color-primary' : 'text-dark-3']}
                  size={theme.spacingUnit * 3}
                />
              </TouchableOpacity>
            </StyledCheckboxWrapper>
          </DeliveryNoteLineCardWrapper>
        </DropShadow>
      </Card>
      <Spacer size={0.8} axis={Spacer.AxisEnum.VERTICAL} />
    </View>
  )
}

export default DeliveryNoteLinePickerCard
