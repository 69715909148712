import { useQuery } from '@tanstack/react-query'
import { addDays } from 'date-fns'
import { Pagination } from '../../domain'
import { Invoice } from '../../domain/Invoice'
import { AccessData } from '../../utilities/context/authenticationContext/authenticationContext.model'
import ApiService from '../api.service'
import { getOneCarrierGroup } from '../carrier/group'
import { getOneProducer } from '../producer/producer'
import { queryClient } from '../../utilities/queryClient'
import FormatUtils from '../../utilities/utils/format'
import { InvoiceStatusEnum } from '../../../enums/invoice'

interface GetAllParams {
  accountId: string
  limit?: number
  pageNumber?: number
  getByIssuer?: boolean
  withPayment?: boolean
  onlyLastOne?: boolean
  recipientId?: string
  status?: InvoiceStatusEnum
  start?: Date
  end?: Date
}

interface CreateInvoiceParams {
  recipientId: string
  parentLines: string[]
  customIdentifier: string
  dueDate: Date
}

const sendInvoiceEmail = async (idInvoice: string): Promise<Invoice> => {
  const response = await ApiService.post(`/invoice/${idInvoice}/email`, {})
  return response
}
const create = async (payload: CreateInvoiceParams): Promise<Invoice> => {
  const response = await ApiService.post(`/invoice`, payload)
  return response
}
const getAll = async ({
  accountId,
  limit = 30,
  pageNumber = 1,
  getByIssuer,
  withPayment,
  onlyLastOne,
  recipientId,
  status,
  start,
  end,
}: GetAllParams): Promise<{ data: Invoice[]; pagination?: Pagination }> => {
  if (!accountId) {
    return { data: [], pagination: undefined }
  }
  const response = await ApiService.get(`/invoice`, {
    limit,
    page: pageNumber,
    accountId,
    getByIssuer: getByIssuer,
    withPayment,
    onlyLastOne,
    recipientId,
    status,
    start,
    end,
  })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const getOne = async (invoiceId: string | undefined, detailled?: boolean): Promise<Invoice> => {
  const response = await ApiService.get(`/invoice/${invoiceId}`, { detailled: detailled })
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

const update = async (invoiceId: string, invoice: any): Promise<Invoice> => {
  const response = await ApiService.patch(`/invoice/${invoiceId}`, invoice).catch((error) => {
    throw error
  })
  return response
}

const getPdf = async (invoiceId: string): Promise<any> => {
  const response = await ApiService.get(`/invoice/${invoiceId}/pdf`)
    .then((result) => result)
    .catch((error) => {
      throw error
    })
  return response
}

// ============================ useGetInvoices hook ============================

export type UseGetInvoicesArgs = {
  accountId: string
  limit?: number
  pageNumber?: number
  getByIssuer?: boolean
  withPayment?: boolean
  onlyLastOne?: boolean
  recipientId?: string
  status?: InvoiceStatusEnum
  start?: Date
  end?: Date
}

function useGetInvoices(args: UseGetInvoicesArgs) {
  function getQueryKey() {
    return [
      'sf_billing_invoices',
      args.accountId,
      args.limit ? `limit_${args.limit}` : '',
      args.pageNumber ? `pageNumber_${args.pageNumber}` : '',
      args.getByIssuer ? 'getByIssuer' : '',
      args.withPayment ? 'withPayment' : '',
      args.onlyLastOne ? 'onlyLastOne' : '',
      args.recipientId ? `recipientId_${args.recipientId}` : '',
      args.status ? `status_${args.status}` : '',
      args.start ? `start_${args.start.toISOString()}` : '',
      args.end ? `end_${args.end.toISOString()}` : '',
    ].filter(Boolean)
  }

  function query() {
    return useQuery<{ data: Invoice[]; pagination?: Pagination }, Error>({
      queryKey: getQueryKey(),
      queryFn: () =>
        getAll({
          accountId: args.accountId,
          limit: args.limit,
          pageNumber: args.pageNumber,
          getByIssuer: args.getByIssuer,
          withPayment: args.withPayment,
          onlyLastOne: args.onlyLastOne,
          recipientId: args.recipientId,
          status: args.status,
          start: args.start,
          end: args.end,
        }),
    })
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  function refetch() {
    queryClient.refetchQueries(getQueryKey())
  }

  return {
    getQueryKey,
    query,
    invalidate,
    refetch,
  }
}

const getDefaultDueDate = async (accessData: AccessData): Promise<Date> => {
  const carrierGroup = accessData.carrierId
    ? await getOneCarrierGroup(
        accessData.carrierGroupId,
        accessData.carrierId,
        accessData.carrierId,
      )
    : null

  const producerData = accessData.producerId
    ? await getOneProducer(accessData.producerId)
    : undefined

  const defaultInvoiceDelay = accessData.carrierId
    ? carrierGroup?.carrier?.invoiceDefaultDelay
    : producerData?.producer?.invoiceDefaultDelay

  const defaultDueDate = addDays(new Date(), defaultInvoiceDelay || 0)

  return defaultDueDate
}

// ============================ useGetOneInvoice hook ============================

export type UseGetOneInvoiceArgs = {
  invoiceId: string
  detailled?: boolean
}

export interface InvoiceExtended extends Invoice {
  priceItems: {
    totalHT: number
    totalVAT: number
    totalTTC?: number
  }[]
  dateInfos: string
}

function useGetOneInvoice(args: UseGetOneInvoiceArgs) {
  function getQueryKey() {
    return ['sf_billing_invoice', args.invoiceId, args.detailled ? 'detailled' : ''].filter(Boolean)
  }

  function query() {
    return useQuery<Invoice, Error, InvoiceExtended>({
      queryKey: getQueryKey(),
      queryFn: () => getOne(args.invoiceId, args.detailled),
      select: (invoice): InvoiceExtended => ({
        ...invoice,
        priceItems: [
          {
            totalHT: invoice.totalAmounts.totalHT,
            totalVAT: invoice.totalAmounts.totalVAT,
            totalTTC: invoice.totalAmounts.totalTTC,
          },
        ],
        dateInfos: `du ${
          invoice.createdDate
            ? FormatUtils.formatDate(invoice.createdDate.toString(), 'DateFullYear')
            : 'N/A'
        } - échéance le ${
          invoice.dueDate
            ? FormatUtils.formatDate(invoice.dueDate.toString(), 'DateFullYear')
            : 'N/A'
        }`,
      }),
    })
  }

  function invalidate() {
    queryClient.invalidateQueries(getQueryKey())
  }

  return {
    getQueryKey,
    query,
    invalidate,
  }
}

export type UseGetRecipientsArgs = {
  accountId: string
}

const InvoiceService = {
  useGetInvoices,
  create,
  getDefaultDueDate,
  useGetOneInvoice,
  update,
  getPdf,
  sendInvoiceEmail,
}

export default InvoiceService
